<template>
  <div class="page-main">
    <div class="page-l" v-if="billId">
      <b-detail-nav :id="billId" />
    </div>
    <div class="page-r" :style="{ height: `${$util.getViewHeight() - 102}px` }">
      <div style="width: 1000px">
        <div class="page-title">基本信息</div>
        <div class="page-form">
          <e-form
            ref="refForm1"
            class="flex-1"
            :config="formConfig"
            :column="formColumn1"
            :data.sync="detailData.pact_bill"
          />
          <e-form
            ref="refForm2"
            class="flex-1"
            :config="formConfig"
            :column="formColumn2"
            :data.sync="detailData.pact_bill"
          />
        </div>

        <div class="page-title">
          兹经甲乙协商同意，出需方(乙方)购进，供方(甲方)售出下列货物，并就本次交易达成如下合同，共同遵守执行
        </div>
        <!-- 表格 -->
        <vxe-grid
          ref="refTable"
          size="mini"
          border
          highlight-hover-row
          show-footer
          highlight-current-row
          :min-height="42"
          :columns="tableColumn"
          :data="detailData.stock_list"
          :keyboard-config="{ isArrow: true }"
          :footer-method="footerMethod"
        >
          <!-- 备注 -->
          <template #default_pact_remark="{ row }">
            <el-input v-model="row.pact_remark" size="mini" clearable />
          </template>
        </vxe-grid>
        <e-form
          ref="refForm3"
          class="flex-1 m-t-10"
          :config="formConfig"
          :column="formColumn3"
          :data.sync="detailData.pact_bill"
        />

        <div class="page-title m-t-10">签名盖章</div>
        <div class="page-form">
          <div class="flex-1">
            <div class="flex f-s-12 m-b-10 m-l-15">
              <span class="f-bold m-r-10 p-y-5">甲方代表签名盖章</span>
              <el-select size="mini" v-model="detailData.pact_bill.yin_zhang_img" disabled>
                <el-option
                  v-for="(item, index) in sealList"
                  :key="index"
                  :label="item.key"
                  :value="item.val"
                  :disabled="item.disabled"
                />
              </el-select>
              <el-link
                v-if="detailData.pact_bill.yin_zhang_img && detailData.pact_bill.status > 1"
                type="primary"
                class="m-l-10"
                style="height: 20px; margin-top: 4px"
                @click.native="$util.windowOpenImg(detailData.pact_bill.yin_zhang_img)"
              >
                查看公章
              </el-link>
            </div>
            <e-form
              ref="refForm4"
              class="flex-1"
              :config="formConfig"
              :column="formColumn4"
              :data.sync="detailData.pact_bill"
            />
          </div>
          <div class="flex-1">
            <div class="flex f-s-12 m-b-10 m-l-15">
              <span class="f-bold m-r-10 p-y-5">乙方代表签名盖章</span>
            </div>
            <e-form
              ref="refForm5"
              class="flex-1"
              :config="formConfig"
              :column="formColumn5"
              :data.sync="detailData.pact_bill"
            />
          </div>
        </div>

        <div class="page-title m-t-10">上传已签约合同文件</div>
        <e-upload-image
          v-if="detailData.pact_bill.pack_photo_list"
          :list.sync="detailData.pact_bill.pack_photo_list"
          isDelete
        />

        <div class="flex flex-x-end">
          <el-button type="primary" size="mini" @click="detailSave">保存</el-button>
          <el-button type="default" size="mini" @click="detailPrint">打印合同</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isUpdatePage: false,
      isDetailPrint: false,
      sealList: [],
      detailData: {
        pact_bill: {},
        stock_list: []
      },
      formConfig: {
        inline: false,
        width: '100%'
      },
      formColumn1: [
        {
          type: 'text',
          title: '合同编码',
          field: 'bill_code',
          disabled: true
        },
        {
          type: 'text',
          title: '供方(甲方)',
          field: 'saler_company_name',
          disabled: true
        },
        {
          type: 'text',
          title: '甲方联系人',
          field: 'saler',
          disabled: true
        },
        {
          type: 'number',
          title: '甲方手机号',
          field: 'saler_phone',
          disabled: true
        },
        {
          type: 'text',
          title: '甲方地址',
          field: 'saler_address',
          disabled: true
        }
      ],
      formColumn2: [
        {
          type: 'date',
          title: '签约日期',
          field: 'pact_time',
          disabled: true,
          format: 'yyyy-MM-dd',
          valueFormat: 'yyyy-MM-dd'
        },
        {
          type: 'text',
          title: '需方(乙方)',
          field: 'buyer_company_name',
          disabled: true
        },
        {
          type: 'text',
          title: '乙方联系人',
          field: 'buyer',
          disabled: true
        },
        {
          type: 'number',
          title: '乙方手机号',
          field: 'buyer_phone',
          disabled: true
        },
        {
          type: 'text',
          title: '乙方地址',
          field: 'buyer_address',
          disabled: true
        }
      ],
      formColumn3: [
        {
          type: 'text',
          title: '一、包装方式',
          field: 'package_type',
          placeholder: '包装方式'
        },
        {
          type: 'text',
          title: '二、合同履行地',
          field: 'pact_action_address',
          placeholder: '合同履行地'
        },
        {
          type: 'text',
          title: '三、运输方式',
          field: 'transport',
          placeholder: '运输方式'
        },
        {
          type: 'text',
          title: '四、付款方式',
          field: 'pay',
          placeholder: '付款方式'
        },
        {
          type: 'text',
          title: '五、质量验收标准',
          field: 'quality_check',
          placeholder: '质量验收标准'
        },
        {
          type: 'text',
          title: '六、违约责任',
          field: 'break_pact',
          placeholder: '违约责任'
        },
        {
          type: 'text',
          title: '七、争议解决',
          field: 'dispute_resolution',
          placeholder: '争议解决'
        },
        {
          type: 'text',
          title: '八、合同生效说明',
          field: 'pact_effect',
          placeholder: '合同生效说明'
        }
      ],
      formColumn4: [
        {
          type: 'text',
          title: '供方单位(甲方)',
          field: 'saler_company_name',
          disabled: true
        },
        {
          type: 'text',
          title: '甲方地址',
          field: 'saler_address',
          disabled: true
        },
        {
          type: 'text',
          title: '甲方代表',
          field: 'saler_name',
          disabled: true
        },
        {
          type: 'text',
          title: '银行账号',
          field: 'saler_bank_code',
          disabled: true
        },
        {
          type: 'text',
          title: '统一社会信用代码',
          field: 'saler_tax_code',
          disabled: true
        },
        {
          type: 'text',
          title: '开户银行',
          field: 'saler_bank_name',
          disabled: true
        }
      ],
      formColumn5: [
        {
          type: 'text',
          title: '需方单位(乙方)',
          field: 'buyer_company_name',
          disabled: true
        },
        {
          type: 'text',
          title: '乙方地址',
          field: 'buyer_address',
          disabled: true
        },
        {
          type: 'text',
          title: '乙方代表',
          field: 'buyer_name',
          disabled: true
        },
        {
          type: 'text',
          title: '银行账号',
          field: 'buyer_bank_code',
          disabled: true
        },
        {
          type: 'text',
          title: '统一社会信用代码',
          field: 'buyer_tax_code',
          disabled: true
        },
        {
          type: 'text',
          title: '开户银行',
          field: 'buyer_bank_name',
          disabled: true
        }
      ],
      tableColumn: [
        {
          title: '序号',
          type: 'seq',
          width: 50
        },
        {
          title: '品名',
          field: 'craft'
        },
        {
          title: '规格(mm)',
          field: 'sales_specification'
        },
        {
          title: '单位',
          field: 'unit'
        },
        {
          title: '单价(元)',
          field: 'sales_price'
        },
        {
          title: '数量',
          field: 'counts'
        },
        {
          title: '金额',
          field: 'sales_amount'
        },
        {
          title: '备注',
          slots: { default: 'default_pact_remark' },
          editRender: { autofocus: '.el-input__inner' }
        }
      ]
    }
  },
  computed: {
    billId() {
      return this.$route.query.id
    }
  },
  watch: {
    detailData: {
      handler() {
        this.isUpdatePage = true
      },
      deep: true
    }
  },
  mounted() {
    this.getSealListData()
    if (this.billId) {
      this.getDetailData()
    }
  },
  methods: {
    // 获取印章数据列表
    getSealListData() {
      this.$api({
        method: 'post',
        url: '/admin/gcvip/yinZhang/search',
        data: {
          keyword: this.keyword,
          sortData: {
            id: 'asc'
          }
        }
      }).then((res) => {
        if (res.status === 200) {
          this.sealList = res.data.list.map((item) => {
            let obj = {
              key: item.name,
              val: item.tu_pian.length ? item.tu_pian[0].url : '',
              disabled: !item.is_open
            }
            return obj
          })
        }
      })
    },
    // 获取-详情数据
    getDetailData() {
      this.$api({
        method: 'post',
        url: '/admin/gcvip/pack/detail',
        params: {
          id: this.billId
        }
      }).then((res) => {
        if (res.data.state === 'ok') {
          this.detailData = this.$util.deepCopy(res.data)
          setTimeout(() => {
            this.isUpdatePage = false
          }, 100)
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    // 表格底部
    footerMethod({ columns, data }) {
      return [
        columns.map((column, columnIndex) => {
          if (columnIndex === 0) return '合计'
          if (['counts', 'sales_amount'].includes(column.field)) {
            return this.sumNum(data, column.field)
          }
        })
      ]
    },
    sumNum(list, field) {
      let listCount = list.map((item) => {
        return item[field]
      })
      return this.$XEUtils.sum(listCount)
    },
    // 详情-保存
    detailSave() {
      this.$api({
        method: 'post',
        url: '/admin/gcvip/pack/saveOrUpdate',
        data: this.detailData
      }).then((res) => {
        if (res.data.state === 'ok') {
          if (this.billId) {
            if (this.isDetailPrint) {
              this.isDetailPrint = false
              this.detailPrintConfirm()
              return
            }
            this.$message.success('保存成功')
            this.getDetailData()
          }
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    // 详情-打印
    detailPrint() {
      if (this.isUpdatePage) {
        this.isDetailPrint = true
        this.detailSave()
      } else {
        this.detailPrintConfirm()
      }
    },
    // 详情-打印-确认
    detailPrintConfirm() {
      window.open(`/sale/contract/print?id=${this.billId}`, '_blank')
    }
  }
}
</script>

<style lang="less" scoped>
.page-main {
  position: relative;
  display: flex;
  height: 100%;
  .page-l {
    margin: 0 10px 10px 0;
  }
  .page-r {
    flex: 1;
    width: 1000px;
    padding-right: 10px;
    overflow-y: auto;
    overflow-x: hidden;
    .scroll-bar();
  }
  .page-title {
    line-height: 40px;
    font-weight: bold;
    font-size: 12px;
    border-bottom: 1px solid @colorGrayD;
    margin-bottom: 10px;
  }
  .page-form {
    display: flex;
    width: 100%;
    margin-top: 10px;
  }
}
</style>
